<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-card outlined rounded="lg">
      <div class="d-flex justify-space-between align-center pl-6">
        <v-tabs v-model="filtersState.tab" color="info">
          <v-tab
            v-for="item in tabs"
            :key="item.text"
            :disabled="submissionsTable.loading"
          >
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <cz-button
          color="buttonSecondary"
          :title="$t('vendor.orders.newSubmission')"
          :icon-src="mdiPlusCircleOutline"
          @click="onNewSubmissionClick"
        />
      </div>

      <v-divider />
      <v-card-text>
        <div
          class="d-flex align-center flex-wrap flex-grow-1"
          style="gap: 20px"
          v-if="filtersState.filters"
        >
          <cz-autocomplete
            v-model="filtersState.filters.documentType"
            hide-details
            multiple
            dense
            use-chips
            small-chips
            item-text="text"
            item-value="value"
            :label="$t('vendor.orderSubmissions.submissionDocumentType')"
            :items="docTypeItems"
            item-removeable
            @item-remove="onFilterItemRemove($event, 'documentType')"
            ref="docTypeAutocomplete"
            @blur="onFilter('docTypeAutocomplete')"
            style="max-width: 350px"
          >
            <template #append-item>
              <v-divider />
              <div
                class="d-flex justify-end px-5 py-3 align-center"
                style="gap: 20px"
              >
                <cz-button
                  :title="$t('common.cancel')"
                  text
                  color="textPrimary"
                  class="text-body-1"
                  @click="onCancelFilter('docTypeAutocomplete')"
                />
                <cz-button
                  :title="$t('common.approve')"
                  color="button"
                  dark
                  text
                  class="text-body-1 font-weight-semibold"
                  @click="onFilter('docTypeAutocomplete')"
                />
              </div>
            </template>
          </cz-autocomplete>

          <cz-autocomplete
            v-model="filtersState.filters.status"
            hide-details
            dense
            multiple
            item-text="text"
            item-value="value"
            :label="$t('vendor.submissionStatus')"
            outlined
            use-chips
            small-chips
            :items="statusItems"
            item-removeable
            @item-remove="onFilterItemRemove($event, 'status')"
            ref="statusAutocomplete"
            :menu-props="{
              closeOnContentClick: false
            }"
            @blur="onFilter('statusAutocomplete')"
            style="max-width: 400px"
          >
            <template #append-item>
              <v-divider />
              <div
                class="d-flex justify-end px-5 py-3 align-center"
                style="gap: 20px"
              >
                <cz-button
                  :title="$t('common.cancel')"
                  text
                  color="textPrimary"
                  class="text-body-1"
                  @click="onCancelFilter('statusAutocomplete')"
                />
                <cz-button
                  :title="$t('common.approve')"
                  color="button"
                  dark
                  text
                  class="text-body-1 font-weight-semibold"
                  @click="onFilter('statusAutocomplete')"
                />
              </div>
            </template>
          </cz-autocomplete>

          <v-spacer />

          <cz-button
            v-if="hasActiveFilters"
            text
            color="info"
            :icon-src="mdiCloseCircleOutline"
            :title="$t('common.resetFilters')"
            @click="initializeFilters(true)"
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- <v-card flat outlined rounded="lg">
      <v-card-text>
        <div
          class="d-flex align-center flex-wrap flex-grow-1"
          style="gap: 20px"
          v-if="filtersState.filters"
        >
          <cz-autocomplete
            v-model="filtersState.filters.documentType"
            hide-details
            multiple
            dense
            use-chips
            small-chips
            item-text="text"
            item-value="value"
            :label="$t('vendor.orderSubmissions.submissionDocumentType')"
            :items="docTypeItems"
            item-removeable
            @item-remove="onFilterItemRemove($event, 'documentType')"
            ref="docTypeAutocomplete"
            @blur="onFilter('docTypeAutocomplete')"
            style="max-width: 350px"
          >
            <template #append-item>
              <v-divider />
              <div
                class="d-flex justify-end px-5 py-3 align-center"
                style="gap: 20px"
              >
                <cz-button
                  :title="$t('common.cancel')"
                  text
                  color="textPrimary"
                  class="text-body-1"
                  @click="onCancelFilter('docTypeAutocomplete')"
                />
                <cz-button
                  :title="$t('common.approve')"
                  color="button"
                  dark
                  text
                  class="text-body-1 font-weight-semibold"
                  @click="onFilter('docTypeAutocomplete')"
                />
              </div>
            </template>
          </cz-autocomplete>

          <cz-autocomplete
            v-model="filtersState.filters.status"
            hide-details
            dense
            multiple
            item-text="text"
            item-value="value"
            :label="$t('vendor.submissionStatus')"
            outlined
            use-chips
            small-chips
            :items="statusItems"
            item-removeable
            @item-remove="onFilterItemRemove($event, 'status')"
            ref="statusAutocomplete"
            :menu-props="{
              closeOnContentClick: false
            }"
            @blur="onFilter('statusAutocomplete')"
            style="max-width: 400px"
          >
            <template #append-item>
              <v-divider />
              <div
                class="d-flex justify-end px-5 py-3 align-center"
                style="gap: 20px"
              >
                <cz-button
                  :title="$t('common.cancel')"
                  text
                  color="textPrimary"
                  class="text-body-1"
                  @click="onCancelFilter('statusAutocomplete')"
                />
                <cz-button
                  :title="$t('common.approve')"
                  color="button"
                  dark
                  text
                  class="text-body-1 font-weight-semibold"
                  @click="onFilter('statusAutocomplete')"
                />
              </div>
            </template>
          </cz-autocomplete>

          <v-spacer />

          <cz-button
            v-if="hasActiveFilters"
            text
            color="info"
            :icon-src="mdiCloseCircleOutline"
            :title="$t('common.resetFilters')"
            @click="initializeFilters(true)"
          />
        </div>
      </v-card-text>
    </v-card> -->
    <v-card outlined class="mt-4" rounded="lg">
      <cz-data-table
        class="row-pointer"
        v-bind="submissionsTable"
        height="calc(100vh - 400px)"
        :options.sync="filtersState.options"
        :columns="filterableColumns"
        :show-expand="currentTab.expandable"
        single-expand
        item-key="_id"
      >
        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <cz-icon
            v-if="item.childSubmissionsIds && item.childSubmissionsIds.length"
            :size="28"
            :ripple="true"
            :src="isExpanded ? mdiChevronUp : mdiChevronDown"
            @click.stop="onItemExpand(item, expand, isExpanded)"
          />
          <div v-else></div>
        </template>

        <template #expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="pa-3 background">
            <v-card outlined rounded="lg">
              <v-card-text>
                <cz-data-table
                  hide-default-footer
                  :columns="childColumns"
                  v-bind="childSubmissionsTable"
                  inner-table
                  fixed-header
                  style="max-height: 300px"
                >
                  <template #item.documentNumber="{ item }">
                    <router-link
                      :to="{
                        name: 'vendor-submission',
                        params: {
                          submissionId: item._id
                        }
                      }"
                      class="font-weight-semibold text-body-1 button--text"
                    >
                      {{ item.documentNumber }}
                    </router-link>
                  </template>
                  <template #item.issueDate="{ item }">
                    <span>{{ formatDateToDisplay(item.issueDate) }}</span>
                  </template>
                  <template #item.createdAt="{ item }">
                    <span>{{ formatDateToDisplay(item.createdAt) }}</span>
                  </template>
                  <template #item.totalAmount="{ item }">
                    {{
                      formatAmountWithCurrency(item.totalAmount, item.currency)
                    }}
                  </template>
                  <template #item.status="{ item }">
                    {{ item.statusText }}
                  </template>
                  <template #item.nav="{ item }">
                    <cz-button
                      :icon-size="24"
                      text
                      :icon-src="mdiChevronLeft"
                      :to="{
                        name: 'vendor-submission',
                        params: {
                          submissionId: item._id
                        }
                      }"
                      color="info"
                    />
                  </template>
                </cz-data-table>
              </v-card-text>
            </v-card>
          </td>
        </template>

        <template #item.documentNumber="{ item }">
          <router-link
            :to="{
              name: 'vendor-submission',
              params: {
                submissionId: item._id
              }
            }"
            class="font-weight-semibold text-title button--text"
          >
            {{ item.documentNumber }}
          </router-link>
        </template>

        <template #item.status="{ item }">
          {{ item.statusText }}
        </template>

        <template #item.documentStatus="{ item }">
          <cz-chip
            v-if="item.documentStatusText"
            :text="item.documentStatusText"
            small
            :color="
              item.documentStatus === SubmissionDocumentStatus.OPEN
                ? 'buttonSecondary darken-1'
                : 'primary'
            "
            dark
          />
        </template>

        <template #item.statusText="{ item }">
          <div class="d-flex align-center" style="gap: 5px">
            <span>{{ item.statusText }}</span>
            <cz-menu-button
              v-if="item.status === 'returned' || item.status === 'rejected'"
              :max-width="300"
              :menu-max-width="350"
              color="info"
              flat
              :button-icon-src="mdiInformationOutline"
              :menuItems="getStatusMenuItems(item)"
            />
          </div>
        </template>

        <template #item.issueDate="{ item }">
          <span>{{ formatDateToDisplay(item.issueDate) }}</span>
        </template>
        <template #item.createdAt="{ item }">
          <span>{{ formatDateToDisplay(item.createdAt) }}</span>
        </template>
        <template #item.totalAmount="{ item }">
          {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
        </template>
        <template #item.vatAmount="{ item }">
          {{ formatAmountWithCurrency(item.vatAmount, item.currency) }}
        </template>
        <template #item.netAmount="{ item }">
          {{ formatAmountWithCurrency(item.netAmount, item.currency) }}
        </template>
        <template #item.customer="{ item }">
          <customer-info-menu
            v-if="item.customer"
            :customer-account="item.customer"
          />
        </template>
        <template #item.relatedOrder="{ item }">
          <order-info-menu
            v-if="item.relatedOrder"
            :order="item.relatedOrder"
          />
        </template>
        <template #item.parentSubmission="{ item }">
          <submission-info-menu
            v-if="item.parentSubmission"
            :submission="item.parentSubmission"
            @show-submission="navToParentSubmission(item.parentSubmissionId)"
          />
        </template>
        <template #item.returnType="{ item }">
          <span>{{ item.returnTypeText }}</span>
        </template>
        <template #item.rejectType="{ item }">
          <span>{{ item.rejectTypeText }}</span>
        </template>
        <template #item.actions="{ item, index }">
          <cz-menu-button
            v-if="
              item.status === 'approved' &&
              submissionDocTypeToMenuItems(
                item.documentType,
                item.documentStatus
              ).length &&
              !item.parentSubmissionId
            "
            :button-icon-src="mdiPlusCircleOutline"
            button-icon-color="button"
            :button-icon-size="24"
            clickable
            flat
            :tooltip="$t('vendor.orderSubmissions.createChildSubmission')"
            :title="$t('vendor.orderSubmissions.createChildSubmission')"
            :menu-items="
              submissionDocTypeToMenuItems(
                item.documentType,
                item.documentStatus
              )
            "
            @action:clicked="onCreateSubmissionClicked($event, item)"
          />
        </template>
        <template #item.nav="{ item }">
          <cz-button
            :icon-size="30"
            text
            :icon-src="mdiChevronLeft"
            :to="{
              name: 'vendor-submission',
              params: {
                submissionId: item._id
              }
            }"
            color="info"
          />
        </template>
      </cz-data-table>
    </v-card>

    <new-submission-dialog
      v-if="createSubmissionDialog.show"
      v-model="createSubmissionDialog.show"
      v-bind="createSubmissionDialog"
      @continue="uploadOrderSubmissionForParentSubmission"
      @close="createSubmissionDialog.show = false"
    />

    <order-lookup-dialog
      v-model="orderLookupDialog.show"
      v-if="orderLookupDialog.show"
      @close="orderLookupDialog.show = false"
      @done="onOrderSelected"
      v-bind="orderLookupDialog"
    />
  </content-layout>
</template>

<script>
import {
  createOrderSubmission,
  getOrders,
  getOrderSubmissions,
  uploadFiles
} from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import {
  CzDataTable,
  CzIcon,
  CzButton,
  CzMenuButton,
  CzAutocomplete,
  CzChip
} from '@/components';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { mapActions } from 'vuex';
import {
  mdiChevronLeft,
  mdiPlusCircleOutline,
  mdiChevronLeftCircleOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiInformationOutline,
  mdiInformation,
  mdiCheckAll,
  mdiAccountClockOutline,
  mdiCloseCircleOutline,
  mdiKeyboardReturn,
  mdiAccountCancelOutline,
  mdiCircleOutline
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import OrderInfoMenu from '@/domain/customer/components/OrderInfoMenu.vue';
import CustomerInfoMenu from '@/domain/customer/components/CustomerInfoMenu.vue';
import OrderLookupDialog from '@/domain/shared/components/OrderLookupDialog.vue';
import { getDocTypeFilterItems, submissionDocTypes } from '../constants';
import { DOCUMENT_PREDICTION_TIMEOUT } from '@/domain/shared/constants';
import SubmissionInfoMenu from '@/domain/customer/components/SubmissionInfoMenu.vue';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import SubmissionDocumentStatus from '@/shared/types/SubmissionDocumentStatus';
import currencyTypes from '@/shared/types/CurrencyTypes';
import isEqual from 'lodash.isequal';
import store from '@/core/store';
import OrderStatus from '@/shared/types/OrderStatus';

export default {
  name: 'VendorSubmissionsPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    NewSubmissionDialog: () =>
      import('@/domain/customer/components/NewOrderDialog.vue'),
    SubmissionInfoMenu,
    CzDataTable,
    CzChip,
    OrderLookupDialog,
    CzAutocomplete,
    CzMenuButton,
    OrderInfoMenu,
    CzIcon,
    CzButton,
    CustomerInfoMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  created() {
    if (this.filtersState.tab === -1) {
      this.filtersState.tab = this.selectedTab || 0;
    }

    if (this.filtersState.filters === null) {
      this.initializeFilters();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'vendor-submission') {
      store.dispatch('vendor/resetFiltersState', {}, { root: true });
    }
    next();
  },
  computed: {
    ...mapFields('vendor', ['vendor', 'filtersState']),
    ...mapFields('auth', ['accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'vendor-home',
            params: {
              id: this.vendor?._id
            }
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('vendor.orderSubmissions.submissionsTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    hasActiveFilters() {
      if (!this.filtersState?.filters) {
        return false;
      }

      const filterValues = Object.values(this.filtersCopy);

      for (const value of filterValues) {
        if (Array.isArray(value)) {
          if (value.length) {
            return true;
          }
        } else if (value) {
          return true;
        }
      }

      return false;
    },
    currentTab() {
      return this.tabs[this.filtersState.tab];
    },
    docTypeItems() {
      return getDocTypeFilterItems(this, this.vendor.vatExempt);
    },
    statusItems() {
      return [
        {
          text: this.$t('vendor.orderSubmissions.status.pending_approval'),
          value: OrderSubmissionStatus.PENDING_APPROVAL,
          icon: mdiAccountClockOutline
        },
        {
          text: this.$t('vendor.orderSubmissions.status.approved'),
          value: OrderSubmissionStatus.APPROVED,
          icon: mdiCheckAll
        },
        {
          text: this.$t('vendor.orderSubmissions.status.returned'),
          value: OrderSubmissionStatus.RETURNED,
          icon: mdiKeyboardReturn
        },
        {
          text: this.$t('vendor.orderSubmissions.status.rejected'),
          value: OrderSubmissionStatus.REJECTED,
          icon: mdiAccountCancelOutline
        }
      ];
    },
    currentStatuses() {
      return this.currentTab.status;
    },
    loadParentSubmissionsOnly() {
      return this.currentStatuses?.includes(OrderSubmissionStatus.APPROVED);
    },
    actions() {
      return this.currentTab.actions;
    },
    filterableColumns() {
      const columns = cloneDeep(this.submissionsTable.columns);
      if (!this.currentTab.excludeColumns?.length) {
        return columns;
      } else {
        return columns.filter(
          (col) => !this.currentTab.excludeColumns.includes(col.value)
        );
      }
    },
    childColumns() {
      const columns = cloneDeep(this.submissionsTable.columns);
      return columns.filter((item) => !!item.isChildColumn);
    }
  },
  data() {
    return {
      SubmissionDocumentStatus,
      currencyTypes,
      mdiChevronLeftCircleOutline,
      mdiInformationOutline,
      mdiCircleOutline,
      mdiCloseCircleOutline,
      mdiInformation,
      mdiChevronLeft,
      mdiChevronUp,
      mdiPlusCircleOutline,
      mdiChevronDown,
      loading: false,
      selectedOrder: null,
      selectedSubmission: null,
      dataLoaded: false,
      filtersCopy: {
        documentType: [],
        status: []
      },
      createSubmissionDialog: {
        show: false,
        title: '',
        inputLabel: null,
        parentSubmission: null,
        loadingTitle: null,
        loading: false,
        uploadProgress: 0,
        relatedOrderId: null,
        documentType: null,
        customerAccountId: null
      },
      orderLookupDialog: {
        show: false,
        vendorId: null,
        items: [],
        loading: false,
        multiselect: false
      },
      submissionsTable: {
        loading: false,
        serverItemsLength: -1,
        columns: [
          {
            text: this.$t('vendor.orders.documentNumber'),
            value: 'documentNumber',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.documentStatus'),
            value: 'documentStatus',
            sortable: true,
            isChildColumn: false,
            width: 150
          },
          {
            text: this.$t('common.documentType'),
            value: 'documentTypeText',
            sortable: true,
            isChildColumn: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.submissionStatus'),
            value: 'status',
            isChildColumn: true,
            sortable: true
          },
          {
            text: this.$t('customer.vendorSubmissions.returnType'),
            value: 'returnType',
            isChildColumn: false,
            sortable: false
          },
          {
            text: this.$t('customer.vendorSubmissions.rejectType'),
            value: 'rejectType',
            isChildColumn: false,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.customerName'),
            value: 'customer',
            sortable: false,
            isChildColumn: false
          },
          {
            text: this.$t('vendor.orderSubmissions.netAmount'),
            value: 'netAmount',
            isChildColumn: false,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.vatAmount'),
            value: 'vatAmount',
            isChildColumn: false,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.totalAmount'),
            value: 'totalAmount',
            isChildColumn: true,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.creationDate'),
            value: 'createdAt',
            isChildColumn: true,
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            isChildColumn: true,
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.relatedOrder'),
            value: 'relatedOrder',
            isChildColumn: false,
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.parentSubmission'),
            value: 'parentSubmission',
            isChildColumn: false,
            sortable: false
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            isChildColumn: false,
            width: 100,
            align: 'center'
          },
          {
            text: '',
            value: 'nav',
            sortable: false,
            isChildColumn: true,
            width: 100,
            align: 'center'
          }
        ],
        items: []
      },
      expandedItems: [],
      childSubmissionsTable: {
        items: [],
        loading: false
      },
      tabs: [
        {
          id: 'submissions',
          text: this.$t('vendor.orderSubmissions.allSubmissions'),
          excludeColumns: ['returnType', 'rejectType', 'parentSubmission'],
          expandable: true,
          status: [
            OrderSubmissionStatus.APPROVED,
            OrderSubmissionStatus.PENDING_APPROVAL,
            OrderSubmissionStatus.REJECTED,
            OrderSubmissionStatus.RETURNED
          ],
          actions: []
        },
        {
          id: 'in-progress',
          text: this.$t('vendor.orderSubmissions.inProgressSubmissions'),
          excludeColumns: ['returnType', 'rejectType', 'actions', 'statusText'],
          status: ['new', 'new_predicted'],
          expandable: false,
          actions: []
        }
      ]
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    formatAmountWithCurrency,
    formatDateToDisplay,
    onSubmissionClicked(item) {
      this.$router.push({
        name: 'vendor-submission',
        params: {
          submissionId: item._id
        }
      });
    },
    initializeFilters(reload = false) {
      this.filtersState.filters = {
        documentType: [],
        status: []
      };

      this.filtersCopy = structuredClone(this.filtersState.filters);

      if (reload) {
        this.loadData(true);
      }
    },
    async loadData(reset = false) {
      if (!this.vendor?._id) {
        return;
      }

      if (reset) {
        this.filtersState.options.page = 1;
      }

      try {
        // this.submissionsTable.items = [];
        this.submissionsTable.loading = true;
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.filtersState.options;

        const $skip = (page - 1) * itemsPerPage;

        let query = {
          status: { $in: this.currentStatuses },
          accountId: this.accountId,
          vendorId: this.vendor._id,
          $populate: ['customer'],
          $limit: itemsPerPage,
          $skip
        };

        if (this.filtersState.filters?.status.length) {
          query.status = { $in: this.filtersState.filters.status };
        }

        if (this.filtersState.filters?.documentType.length) {
          query.documentType = { $in: this.filtersState.filters.documentType };
        }

        if (this.loadParentSubmissionsOnly) {
          query = {
            ...query
            // parentSubmissionId: { $exists: false }
          };
        }

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        const { data, total } = await getOrderSubmissions(query);
        this.dataLoaded = true;

        this.submissionsTable.serverItemsLength = total;
        this.submissionsTable.items = data;
      } finally {
        this.submissionsTable.loading = false;
      }
    },
    async onNewSubmissionClick() {
      this.orderLookupDialog.vendorId = this.vendor._id;
      this.orderLookupDialog.show = true;
      if (!this.orderLookupDialog.items.length) {
        try {
          this.orderLookupDialog.loading = true;
          const { data: orders } = await getOrders({
            vendorId: this.vendor._id,
            status: OrderStatus.OPEN
          });

          this.orderLookupDialog.items = orders;
        } finally {
          this.orderLookupDialog.loading = false;
        }
      } else {
        this.orderLookupDialog.show = true;
      }
    },
    onOrderSelected([order]) {
      this.orderLookupDialog.show = false;
      this.$nextTick(() => {
        this.createSubmissionDialog.title = this.$t(
          'vendor.orders.newSubmissionInfo',
          [order.orderNumber]
        );
        this.createSubmissionDialog.parentSubmission = null;
        this.createSubmissionDialog.documentType = null;
        this.createSubmissionDialog.relatedOrderId = order._id;
        this.createSubmissionDialog.customerAccountId = order.accountId;
        this.createSubmissionDialog.show = true;
      });
    },
    async loadChildSubmissions(parentSubmissionId) {
      if (!parentSubmissionId) {
        return;
      }
      try {
        this.childSubmissionsTable.loading = true;
        const { data } = await getOrderSubmissions({
          parentSubmissionId,
          status: { $nin: ['cancelled'] },
          accountId: this.accountId
        });
        this.childSubmissionsTable.items = data;
      } finally {
        this.childSubmissionsTable.loading = false;
      }
    },
    customerMenuItems(item) {
      if (!item.customer) {
        return [];
      }

      return [
        {
          title: this.$t('vendor.orderSubmissions.customerName'),
          subtitle: item.customer.companyName
        },
        {
          title: this.$t('vendor.orderSubmissions.businessId'),
          subtitle: item.customer.businessId
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonName'),
          subtitle: item.customer.contactPersonName
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonEmail'),
          subtitle: item.customer.contactPersonEmail
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonPhone'),
          subtitle: item.customer.contactPersonPhone
        }
      ];
    },
    submissionDocTypeToMenuItems(docType, docStatus) {
      const menuItems = submissionDocTypes(
        this,
        this.vendor.vatExempt,
        docType,
        docStatus
      ).map((item) => {
        return {
          id: item.value,
          title: item.text,
          icon: item.icon
        };
      });

      return menuItems;
    },
    onItemExpand(item, expand, isExpanded) {
      this.childSubmissionsTable.items = [];
      this.expandedItems = [item._id];
      expand(!isExpanded);
      this.loadChildSubmissions(item._id);
    },
    onCreateSubmissionClicked(action, parentSubmission) {
      this.createSubmissionDialog.parentSubmission =
        structuredClone(parentSubmission);
      this.createSubmissionDialog.customerAccountId =
        parentSubmission.customerAccountId;
      this.createSubmissionDialog.title = this.$t(
        'vendor.orderSubmissions.createSubmissionForSubmission',
        [
          this.$t(`vendor.submissionDocTypes.${action.id}`),
          parentSubmission.documentNumber
        ]
      );

      this.createSubmissionDialog.inputLabel = this.$t(
        `vendor.submissionDocTypes.${action.id}`
      );
      this.createSubmissionDialog.documentType = action.id;
      this.createSubmissionDialog.show = true;
    },
    onFilterItemRemove(item, fieldName) {
      const index = this.filtersState.filters[fieldName].indexOf(item.value);
      if (index !== -1) {
        this.filtersState.filters[fieldName].splice(index, 1);
      }
    },
    async uploadOrderSubmissionForParentSubmission(file) {
      try {
        this.createSubmissionDialog.loading = true;
        this.createSubmissionDialog.loadingTitle = this.$t(
          'vendor.orders.uploadingDocument'
        );

        const [documentFile] = await uploadFiles([file], this.accountId);

        const document = await createOrderSubmission({
          relatedOrderId: this.createSubmissionDialog.parentSubmission
            ? this.createSubmissionDialog.parentSubmission.relatedOrderId
            : this.createSubmissionDialog.relatedOrderId,
          parentSubmissionId:
            this.createSubmissionDialog.parentSubmission?._id || undefined,
          accountId: this.accountId,
          documentType: this.createSubmissionDialog.documentType || undefined,
          customerAccountId: this.createSubmissionDialog.customerAccountId,
          documentFileId: documentFile._id,
          vendorId: this.vendor._id,
          status: 'new'
        });

        this.createSubmissionDialog.loadingTitle = this.$t(
          'vendor.orders.processingDocument'
        );

        // after document has been created, we need to wait about 4-5 seconds to order to be processed
        // In the future it will be done via messaging queue or via websockets.
        setTimeout(() => {
          clearInterval(this.uploadDocTimer);
          // this.countSubmissions();
          this.handleProcessedDocument(document._id);
          this.createSubmissionDialog.show = false;
        }, DOCUMENT_PREDICTION_TIMEOUT);

        this.createSubmissionDialog.uploadProgress +=
          100 / (DOCUMENT_PREDICTION_TIMEOUT / 500);

        this.uploadDocTimer = setInterval(() => {
          this.createSubmissionDialog.uploadProgress +=
            100 / (DOCUMENT_PREDICTION_TIMEOUT / 500);
        }, 500);
      } catch (error) {
        this.createSubmissionDialog.show = false;
        this.createSubmissionDialog.loading = false;
        this.showErrorToastMessage(error.message);
      }
    },
    async handleProcessedDocument(docId) {
      this.$router.push({
        name: 'vendor-submission',
        params: {
          submissionId: docId,
          predicted: true
        }
      });
    },
    navToParentSubmission(submissionId) {
      this.$router.push({
        name: 'vendor-submission',
        params: {
          submissionId
        }
      });
    },
    getStatusMenuItems(item) {
      if (!item) {
        return [];
      }

      switch (item.status) {
        case OrderSubmissionStatus.RETURNED:
          return [
            {
              title: this.$t('customer.vendorSubmissions.returnType'),
              subtitle: item.returnTypeText
            },
            {
              title: this.$t('customer.vendorSubmissions.note'),
              subtitle:
                item.note || this.$t('customer.vendorSubmissions.noNotes')
            }
          ];
        case OrderSubmissionStatus.REJECTED:
          return [
            {
              title: this.$t('customer.vendorSubmissions.rejectType'),
              subtitle: item.rejectTypeText
            },
            {
              title: this.$t('customer.vendorSubmissions.note'),
              subtitle:
                item.note || this.$t('customer.vendorSubmissions.noNotes')
            }
          ];
        default:
          return [];
      }
    },
    onFilter(ref) {
      this.$refs[ref].$children[0].blur();
      if (!isEqual(this.filtersState.filters, this.filtersCopy)) {
        this.loadData(true);
        this.filtersCopy = structuredClone(this.filtersState.filters);
      }
    },
    onCancelFilter(ref) {
      this.$refs[ref].$children[0].blur();
      this.filtersState.filters = structuredClone(this.filtersCopy);
    }
  },
  watch: {
    vendor: {
      handler() {
        this.loadData();
      }
    },
    'filtersState.tab': {
      handler() {
        this.loadData(true);
      }
    },

    'filtersState.options': {
      handler() {
        this.loadData();
      }
    },
    'createSubmissionDialog.show': {
      handler(value, oldValue) {
        if (!value && oldValue) {
          this.createSubmissionDialog.parentSubmission = null;
          this.createSubmissionDialog.loadingTitle = null;
          this.createSubmissionDialog.loading = false;
          this.createSubmissionDialog.inputLabel = null;
          this.createSubmissionDialog.relatedOrderId = null;
          this.createSubmissionDialog.documentType = null;
          this.createSubmissionDialog.customerAccountId = null;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
